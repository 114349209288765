import React from "react";
import { Box, Button } from "@mui/material"; // Import Button from MUI
import PicoChewLogo from "../images/picochew-512x512.png";

export default function WebApps() {
  console.log("Rendering");
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      marginTop={"15vh"}
      zIndex={2}
    >
      <h1>Pico Chew Bakehouse</h1>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <img
          src={PicoChewLogo}
          alt="Pico Chew Bakehouse"
          style={{ maxWidth: "50%", height: "auto", marginBottom: "10px" }}
        />
        <Button
          variant="outlined"
          color="primary"
          href="https://picochew.com.au"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: "bold",
            textTransform: "none",
            borderColor: "white",
            color: "white",
            backgroundColor: "transparent",
            width: "50%", // Set button width to 50%
            "&:hover": {
              backgroundColor: "white",
              color: "primary.main",
            },
          }}
        >
          Go to Site
        </Button>
      </Box>
      <p style={{ marginTop: "20px", textAlign: "center", maxWidth: "750px" }}>
        This online cookie store was built with React, and uses Firebase for
        backend services. This store has been integrated with Stripe for secure
        payment processing.
      </p>
      <p style={{ marginTop: "20px", textAlign: "center", maxWidth: "800px" }}>
        The client side has been designed to be clean and simple, optimize for
        mobile devices to provide an app experience.
      </p>
      <p style={{ marginTop: "20px", textAlign: "center", maxWidth: "800px" }}>
        The admin side provides the ability to view all due and completed
        customer orders. To add, remove and update products, along with amending
        all the configuration of the site, from availability, (pickup times /
        delivery days), to closure days and periods, amending delivery ranges
        and charges, create PROMO codes and more.
      </p>
    </Box>
  );
}
