import React, { Component } from "react";
import { TECH } from "./data";
import { connect } from "react-redux";
import Info from "./info";
import Skills from "./skills/skills";
import { showLoader, hideLoader } from "../../actions/application";
import "./about.css";

class About extends Component {
  constructor(props) {
    super(props);
    props.dispatch(showLoader());
  }

  handleLoader = () => {
    imagesLoaded(this.galleryElement, this.props);
  };

  render() {
    return (
      <div className="about">
        <div className="about-inner-container">
          <div className="about-content">
            <Info />
          </div>
          <div
            className="skills"
            ref={(element) => {
              this.galleryElement = element;
            }}
          >
            <div className="row2">
              <Skills tech={TECH[10]} loaded={this.handleLoader} />
              <Skills tech={TECH[12]} loaded={this.handleLoader} />
              <Skills tech={TECH[11]} loaded={this.handleLoader} />
            </div>
            <div className="row2">
              <Skills tech={TECH[0]} loaded={this.handleLoader} />
              <Skills tech={TECH[1]} loaded={this.handleLoader} />
            </div>
            <div className="row3">
              <Skills tech={TECH[2]} loaded={this.handleLoader} />
              <Skills tech={TECH[3]} loaded={this.handleLoader} />
              <Skills tech={TECH[4]} loaded={this.handleLoader} />
            </div>
            <div className="row2">
              <Skills tech={TECH[5]} loaded={this.handleLoader} />
              <Skills tech={TECH[6]} loaded={this.handleLoader} />
            </div>
            <div className="row3">
              <Skills tech={TECH[7]} loaded={this.handleLoader} />
              <Skills tech={TECH[8]} loaded={this.handleLoader} />
              <Skills tech={TECH[9]} loaded={this.handleLoader} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function imagesLoaded(parentNode, props) {
  const imgElements = [...parentNode.querySelectorAll("img")];
  for (let i = 0; i < imgElements.length; i += 1) {
    const img = imgElements[i];
    if (!img.complete) {
      return false;
    }
  }
  setTimeout(() => {
    props.dispatch(hideLoader());
  }, 1000);
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(About);
