import AndroidLogo from "../../images/tech-logo/android-logo.svg";
import ReactLogo from "../../images/tech-logo/react-logo.svg";
import FlutterLogo from "../../images/tech-logo/flutter-logo.svg";
import JavaLogo from "../../images/tech-logo/java-logo.svg";
import KotlinLogo from "../../images/tech-logo/kotlin-logo.svg";
import CsharpLogo from "../../images/tech-logo/Csharp-logo.svg";
import MySQLLogo from "../../images/tech-logo/mysql-logo.svg";
import JavaScriptLogo from "../../images/tech-logo/javascript-logo.svg";
import HTMLLogo from "../../images/tech-logo/html-logo.svg";
import CSSLogo from "../../images/tech-logo/css-logo.svg";
import AWSLogo from "../../images/tech-logo/aws-logo.svg";
import FirebaseLogo from "../../images/tech-logo/firebase.svg";
import Gitlab from "../../images/tech-logo/gitlab.svg";

export const TECH = [
  { name: "React", url: "https://reactjs.org/", logo: ReactLogo },
  { name: "Flutter", url: "https://flutter.dev/", logo: FlutterLogo },
  { name: "Android", url: "https://developer.android.com/", logo: AndroidLogo },
  {
    name: "Java",
    url: "https://www.oracle.com/java/technologies/",
    logo: JavaLogo,
  },
  { name: "Kotlin", url: "https://kotlinlang.org/", logo: KotlinLogo },
  { name: ".NETcore", url: "https://dotnet.microsoft.com/", logo: CsharpLogo },
  { name: "SQL", url: "https://www.mysql.com/", logo: MySQLLogo },
  {
    name: "JavaScript",
    url: "https://www.javascript.com/",
    logo: JavaScriptLogo,
  },
  { name: "HTML", url: "https://html.spec.whatwg.org/", logo: HTMLLogo },
  {
    name: "CSS",
    url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    logo: CSSLogo,
  },
  { name: "AWS", url: "https://aws.amazon.com/", logo: AWSLogo },
  { name: "Firebase", url: "https://firebase.google.com", logo: FirebaseLogo },
  { name: "Gitlab", url: "https://gitlab.com", logo: Gitlab },
];
