import React from "react";
import { NavLink } from "react-router-dom";
import "./header.css";

export default function Header() {
  const navList = [];
  for (const [index, value] of NAVS.entries()) {
    navList.push(
      <NavLink
        key={index}
        to={value.path}
        exact
        activeStyle={{ color: "forestgreen" }}
        className="link"
      >
        {value.name}
      </NavLink>
    );
  }
  return (
    <div className="header">
      <h1 className="title">Digital Contriver</h1>
      {navList}
    </div>
  );
}
const NAVS = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Mobile Apps", path: "/mobile" },
  { name: "Web Apps", path: "/web" },
  { name: "Contact", path: "/contact" },
];
