import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { APP_DETAILS } from "./data";
import { Context } from "../../../screenContext";
import "./app-details.css";

export default function AppDetails(props) {
  const index = APP_DETAILS.findIndex((app) => app.title === props.appTitle);
  const screen = useContext(Context);
  return (
    <div className="app-info">
      <div className="app-info-inner-con">
        <div className="title-and-logo">
          <h1>{APP_DETAILS[index].title}</h1>
          <img
            className="logo-img"
            src={APP_DETAILS[index].logo}
            alt="logo"
            onLoad={() => props.loader(-1)}
          />
        </div>

        <h3 style={{ textAlign: "left" }}>{APP_DETAILS[index].platform}</h3>
        <textarea
          style={{ marginTop: "5%" }}
          value={APP_DETAILS[index].desc}
          className="desc"
          spellcheck="false"
          disabled="true"
        />
        <Button
          className="btn"
          variant="outline-light"
          size={screen.width > 1200 ? "lg" : screen.height > 450 ? "" : "sm"}
          href={APP_DETAILS[index].store}
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play Store
        </Button>
        {/* {APP_DETAILS[index].code != null ? (
          <Button
            className="btn"
            variant="outline-light"
            size={screen.width > 1200 ? "lg" : screen.height > 450 ? "" : "sm"}
            href={APP_DETAILS[index].code}
            target="_blank"
            rel="noopener noreferrer"
          >
            Source code
          </Button>
        ) : (
          <React.Fragment />
        )} */}
      </div>
    </div>
  );
}
