import React from "react";

export default function Info() {
  return (
    <>
      <h1 style={{ marginBottom: "10%" }}>About</h1>
      <h2>
        <i>Daryl Crosbie</i>
      </h2>
      <h3 style={{ marginBottom: "10%" }}>Software Developer</h3>
      <p>AWS Certified Developer - Associate</p>
      <br />
      <p>From Dublin, Ireland; based in Perth, Western Australia.</p>

      <p>
        I enjoy developing my skills in full stack web development, with React
        at the front and Firebase or AWS at the back, along with creating mobile
        apps for Android and iOS.
      </p>
    </>
  );
}
