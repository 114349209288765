import React, { useState, useContext } from "react";
import { Lang, useFormInputValidation } from "react-form-input-validation";
import CircularProgress from "@mui/material/CircularProgress";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import { Context } from "../screenContext";

export default function DeleteUserAccount() {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const [fields, errors, form] = useFormInputValidation(
    {
      app: "Liberty",
      email: "",
    },
    {
      app: "required",
      email: "required|email",
    }
  );

  form.useLang(Lang.en);

  const onSubmit = async (event) => {
    setSending(true);
    const isValid = await form.validate(event);

    if (isValid) {
      console.log("Calling emailJS", fields, errors);
      emailjs
        .send(
          "myGmail",
          "template_yahh7kc",
          fields,
          "user_SBAGatrLxdCXx7GPAAO4H"
        )
        .then(function (res) {
          console.log("Success");
          alert("Message Sent.");
          setSending(false);
          setSent(true);
        })
        .catch(function (err) {
          console.error("Failed", err);
          alert("Message Not Sent");
          setSending(false);
        });
      fields.email = "";
    } else {
      setSending(false);
    }
  };
  const screen = useContext(Context);

  return (
    <div className="outer-container">
      <div>
        <h1>Delete User Account Request</h1>
        <br />

        <form onSubmit={onSubmit}>
          <div className="form-wrapper">
            <div>
              <div className="form-group-row">
                <label htmlFor="app">App</label>

                <input
                  type="text"
                  name="change to 'app' to allow editing"
                  className="form-control"
                  onBlur={form.handleBlurEvent}
                  onChange={form.handleChangeEvent}
                  value={fields.app}
                />

                <label className="error">
                  {errors.name ? errors.name : ""}
                </label>
              </div>
              <div className="form-group-row">
                <label htmlFor="inputEmail">Associated E-mail</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  onBlur={form.handleBlurEvent}
                  onChange={form.handleChangeEvent}
                  value={fields.email}
                />

                <label className="error">
                  {errors.email ? errors.email : ""}
                </label>
              </div>
            </div>
            <div className="form-group-row">
              {sending ? (
                <CircularProgress />
              ) : !sent ? (
                <Button
                  type="submit"
                  variant="outline-light"
                  size={
                    screen.width > 1000 ? "lg" : screen.width > 450 ? "" : "sm"
                  }
                >
                  Send
                </Button>
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
