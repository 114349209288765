import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

export default function ControlledCarousel({ images, loader }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel fade interval={2000} activeIndex={index} onSelect={handleSelect}>
      {images.map((src, idx) => (
        <Carousel.Item key={idx}>
          <Image className="d-block w-100" src={src} onLoad={loader} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
